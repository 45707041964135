@import "../../assets/scss/variables";

.header {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  background-color: white;
  
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: $max-width;
    height: $header-height;
    padding-left: 3em;
    padding-right: 3em;

    &--links {
      display: flex;
      flex-direction: row;
      width: 50%;
      justify-content: space-around;

      &-item {
        cursor: pointer;
      }
    }

    &--button {
      button {
        background-color: $green;
        font-size: 1.1rem;
        font-weight: 700;
      }
    }
  }

  .css-1x7skt0 {
    background-color: inherit !important;
    box-shadow: none !important;
  }
  .css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: inherit;
    box-shadow: none;
  }
}

@media (max-width: 500px) {
  .header {
    &__container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
