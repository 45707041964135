@import "./assets/scss/variables";

.app-container {

  min-height: 100vh;
  margin: auto;
 
  width: 100vw;
  // background-image: url('./assets/imgs/Background_mountains.svg');
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: center bottom;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  font-weight: 300;
  white-space: pre-line;
  color: $text-primary;
}

.text-title {
  font-family: 'Fredoka';
  font-weight: 500;
}

img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

input {
  text-align: center;
  padding: 0.7em 2em 0.7em;
  border: 0.5px solid $gray;
  border-radius: 50px;
  color: $blue;
  font-size: 1rem;
  width: 100%;
}

input:read-only {
  color: $green !important;
  border: 1px solid $green;
}

.good-button {
  cursor: pointer;
  background-color: $blue;
  color: white;
  border-radius: 50px;
  border: 0;
  padding: 0.5em 2.5em 0.5em;
  font-size: 1.4rem;
  font-weight: 600;
}

.good-button:hover {
  background-color: $blue-light;
}

.good-button:disabled {
  cursor: default;
  background-color: $gray;
}