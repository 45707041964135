$text-primary: #344478;
$blue: #344378;
$blue-light:#717ca1;
$green: #97ECA5;
$gray: #dbd8d8;
$max-width: 70em;
$header-height: 5em;

// $main-container-width: 25em;
// $main-container-height: 30em;

