@import "../../assets/scss/variables";

.home {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  align-items: center;
  margin: auto;
  margin-top: 2em;
  min-height: Calc(100vh - $header-height);
  max-width: $max-width;

  background-image: url("../../assets/imgs/Goodstake_Landing_Background.svg");
  background-repeat: no-repeat;
  background-size: cover;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 4em;
    font-size: 3.12rem;
    color: $blue;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    button {
      margin-top: 1em;
      margin-bottom: 2em;
      width: 10em;
    }
  }

  &__flow {
    margin-top: 7em;
    height: 30em;

    display: flex;
    flex-direction: column;
    align-content: space-around;

    justify-content: end;
    width: 80%;

    &--items {
      display: flex;
      flex-direction: row;
    }
  }

  &__ubi {
    margin-top: 8em;
    color: white;

    .text-title {
      font-size: 4rem;
    }

    p {
      color: white;
      padding-left: 5em;
      padding-right: 5em;
      font-size: 1.2rem;
    }
  }

  &__services {
    margin-top: 4em;
    margin-bottom: 2em;
    color: white;
    width: 90%;

    h3 {
      color: white;
      font-weight: 600;
      font-size: 1.5em;
    }

    &--items {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;
    }
  }

  &__made-at {
    margin-top: 12em;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    &--description {
      width: 22em;
      padding: 2.5em;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50px;
      font-weight: 600;
    }
  }

  &__partners {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top: 9em;
    margin-bottom: 9em;
    width: 100%;

    h2 {
      font-weight: 900;
      padding-bottom: 1em;
    }

    &--list {
      background-color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      text-align: center;
      justify-content: space-around;
      border-radius: 50px;
      width: 40em;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

      &-partner {
        cursor: pointer;
        padding: 1.5em;
        width: 7em;
        img {
          object-fit: contain;
        }
      }
    }
  }
}

.flow-stage {
  width: 100%;

  &__icon-container {
    margin: auto;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    padding: 1.5em;
    border: 3px solid white;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    &--icon {
      color: white;
      font-size: 2.5rem;
    }
  }

  &__text {
    padding-top: 1em;
    color: white;
  }
}

.service {
  width: 18em;

  &__title {
    background-color: $green;
    color: $blue;
    padding: 0.8em;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.1rem;
  }

  &__text {
    padding-top: 1em;
    color: white;
    font-weight: 600;
  }
}

.eth-global {
  h2 {
    color: white;
    font-weight: 900;
    line-height: 0.3em;
    font-size: 1.8rem;
  }
}

@media (max-width: 900px) {
  .home {
    &__flow {
      &--items {
        flex-direction: row;
      }
    }

    &__services {
      &--items {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .service {
    &__text {
      padding-bottom: 2.5em;
      color: white;
      font-weight: 600;
    }
  }
}

@media (max-width: 700px) {
  .home {
    //max-width: 100%;

    &__made-at {
      margin-top: 6em;
      flex-direction: column;
    }

    &__partners {
      margin-top: 9em;
      margin-bottom: 9em;
      width: 100%;

      &--list {
        display: flex;
        flex-direction: column;
        width: 15em;
      }
    }
  }

  .service {
    &__text {
      padding-bottom: 2.5em;
      color: white;
      font-weight: 600;
    }
  }
}

@media (max-width: 500px) {
  .home {
    &__title {
      width: 95%;
      margin-top: 1em;
    }

    &__flow {
      margin-top: 10em;
      justify-content: center;
      &--items {
        display: flex;
        flex-direction: column;
      }
    }

    &__ubi {
      margin-top: 8em;
      color: white;
  
      .text-title {
        font-size: 3rem;
      }
  
      p {
        color: white;
        padding-left: 2em;
        padding-right: 2em;
        font-size: 1.2rem;
      }
    }

    &__made-at {
  
      &--description {
        width: 80%;
      }
    }
  }

  .flow-stage {
    &__icon-container {
      margin: auto;
      width: 5em;
      height: 5em;
      border-radius: 50%;
      padding: 1.5em;
      border: 3px solid $green;
      color: $green;

      display: flex;
      align-items: center;
      justify-content: center;

      &--icon {
        color: $green;
        font-size: 2.5rem;
      }
    }

    &__text {
      margin-bottom: 2em;
      color: $green;
    }
  }
}
