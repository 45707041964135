@import "../../assets/scss/variables";

.footer {
  width: 100%;
  background-color: $blue;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: $max-width;
    height: $header-height;
    padding-left: 3em;
    padding-right: 3em;

    &-left {
      padding-left: 2em;
      width: 9.5em;
    }

    &-right {
      display: flex;
      flex-direction: row;
      
      p {
        padding-left: 2em;
        padding-right: 2em;
        color: white;
      }
    }
  }
}

.social-networks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  justify-items: center;
  width: 5em;

  &__item {

    font-size: 1.5rem;
    a {
      color: white !important;
    }
  }
}
